var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"cr-modal"},[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('h2',{staticClass:"d-flex",staticStyle:{"width":"calc(100% - 24px)"}},[_vm._v("Complete Chat")])])]),_c('v-card-text',[_c('v-form',{ref:"complete-chat-form"},[_c('div',[_c('span',{staticClass:"chat-label"},[_vm._v("Name:")]),_vm._v(" "+_vm._s(_vm.finishedChat.name || 'Anonymous')+" ")]),_c('p',[_c('span',{staticClass:"chat-label"},[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.finishedChat.email || 'Email not provided')+" ")]),_c('p',[_vm._v("Please answer the dropdowns below and complete the chat.")]),_c('div',[_c('label',[_vm._v("Reason")]),_c('CRSelect',{attrs:{"items":_vm.chatReasons,"item-text":"label","return-object":"","placeholder":"Please select the reason for the chat","rules":[_vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Reason For Chat is Required',
            error: 'Reason For Chat is Required',
          })]},model:{value:(_vm.selectedChatReason),callback:function ($$v) {_vm.selectedChatReason=$$v},expression:"selectedChatReason"}})],1),(_vm.selectedChatReason?.key === 'other')?_c('div',[_c('label',[_vm._v("Other Reason")]),_c('CRInput',{attrs:{"rules":[_vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Other reason is required when other is selected',
            error: 'Other reason is required when other is selected',
          })]},model:{value:(_vm.otherMessage),callback:function ($$v) {_vm.otherMessage=$$v},expression:"otherMessage"}})],1):_vm._e(),_c('div',[_c('label',[_vm._v("Chat Outcome")]),_c('CRSelect',{attrs:{"items":_vm.chatOutcomes,"item-text":"label","return-object":"","placeholder":"Please select the outcome of the chat","rules":[_vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Chat Outcome is Required',
            error: 'Chat Outcome is Required',
          })]},model:{value:(_vm.selectedChatOutcome),callback:function ($$v) {_vm.selectedChatOutcome=$$v},expression:"selectedChatOutcome"}})],1)])],1),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-end":""}},[_c('div',{staticClass:"button-cont"},[_c('div',{staticStyle:{"margin-right":"-1.5%"}},[_c('v-btn',{attrs:{"id":"call-center-complete-chat-complete-button","color":"primary"},on:{"click":_vm.completeChat}},[_vm._v(" Complete ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card class="cr-modal">
    <v-card-title>
      <div class="d-flex align-center" style="width: 100%">
        <h2 class="d-flex" style="width: calc(100% - 24px)">Complete Chat</h2>
      </div>
    </v-card-title>
    <v-card-text>
      <v-form ref="complete-chat-form">
        <div>
          <span class="chat-label">Name:</span> {{ finishedChat.name || 'Anonymous' }}
        </div>
        <p>
          <span class="chat-label">Email:</span> {{ finishedChat.email || 'Email not provided' }}
        </p>
        <p>Please answer the dropdowns below and complete the chat.</p>
        <div>
          <label>Reason</label>
          <CRSelect v-model="selectedChatReason"
            :items="chatReasons"
            item-text="label"
            return-object
            placeholder="Please select the reason for the chat"
            :rules="[isRequired(true, isNotEmpty, {
              req: 'Reason For Chat is Required',
              error: 'Reason For Chat is Required',
            })]" />
        </div>
        <div v-if="selectedChatReason?.key === 'other'">
          <label>Other Reason</label>
          <CRInput v-model="otherMessage"
            :rules="[isRequired(true, isNotEmpty, {
              req: 'Other reason is required when other is selected',
              error: 'Other reason is required when other is selected',
            })]" />
        </div>
        <div>
          <label>Chat Outcome</label>
          <CRSelect v-model="selectedChatOutcome"
            :items="chatOutcomes"
            item-text="label"
            return-object
            placeholder="Please select the outcome of the chat"
            :rules="[isRequired(true, isNotEmpty, {
              req: 'Chat Outcome is Required',
              error: 'Chat Outcome is Required',
            })]" />
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-layout justify-end>
        <div class="button-cont">
          <div style="margin-right: -1.5%">
            <v-btn
              id="call-center-complete-chat-complete-button"
              color="primary"
              @click="completeChat"
            >
              Complete
            </v-btn>
          </div>
        </div>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import chats from '@/services/chats'
import { EventBus } from '@/utils/event-bus'
import { authComputed, callCenterComputed } from '@/state/helpers'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { ChatCompleteRequest } from '@/models/dto'
import { mapActions } from 'vuex'

export default {
  props: {
    finishedChat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedChatReason: null,
      selectedChatOutcome: null,
      otherMessage: null,
      broadcastChannel: null,
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    ...callCenterComputed,
  },
  async mounted() {
    this.broadcastChannel = new BroadcastChannel('flex-channel')
    this.broadcastChannel.addEventListener('message', (event) => {
      if (event.data?.action_name === 'close_dialog') {
        this.closeNoSubmit()
      }
    })
  },
  methods: {
    ...mapActions('callCenter', ['removeFromChatWrapUpQueue', 'openNextChatCompleteDialog']),
    async completeChat() {
      const valid = this.$refs['complete-chat-form'].validate()
      if (valid) {
        const chatCompleteRequest = {
          chatReasonId: this.selectedChatReason?.id,
          chatOutcomeId: this.selectedChatOutcome?.id,
          otherReasonMessage: this.otherMessage
        }

        await chats.completeChat(this.finishedChat?.channelSid, chatCompleteRequest)
        this.close()
      }
    },
    close() {
      this.removeFromChatWrapUpQueue()
      this.broadcastChannel.postMessage({
        type: 'cr_internal',
        action_name: 'close_dialog',
      })
      this.openNextChatCompleteDialog()
    },
    closeNoSubmit() {
      this.removeFromChatWrapUpQueue()
      this.$store.dispatch('app/closeDialog')
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep label {
  font-weight: bold;
}
.chat-label {
  font-weight: bold;
}
</style>
